import { ArrowLeft } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './NotFound.module.css';

interface NotFoundProps {
  product?: boolean;
}

function NotFound({ product = false }: NotFoundProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const returnButton = ((location.key === 'default')
    ? (<Button as={Link} to="/" size="sm" className={styles.backBtn}><ArrowLeft /> Back to My Ideas</Button>)
    : (<Button onClick={() => navigate('/', { replace: true })} size="sm" className={styles.backBtn}><ArrowLeft /> Back to previous page</Button>)
  );

  if (product) {
    return (
      <div className={styles.notFound}>
        <h2>Product or Category Not Found</h2>
        <p>
          The product or category you are looking for may have moved or been deleted.
        </p>
        {returnButton}
      </div>
    );
  }
  return (
    <div className={styles.notFound}>
      <h2>Page Not Found</h2>
      <p>
        The page you were looking for does not exist.
      </p>
      {returnButton}
    </div>
  );
}

export default NotFound;
